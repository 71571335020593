import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

const OCP_DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  width: '400px',
  disableClose: true,
  hasBackdrop: true,
  panelClass: 'ocp-dialog-panel',
};

@Injectable({
  providedIn: 'root',
})
export class OcpDialogService {
  constructor(private _dialog: MatDialog) {}

  openDialog<TComponent, TReturn = unknown, TDialog = any>(
    component: ComponentType<TComponent>,
    config?: MatDialogConfig<TDialog>,
  ): MatDialogRef<TComponent, TReturn> {
    const panelClasses = [
      OCP_DEFAULT_DIALOG_CONFIG.panelClass,
      ...(Array.isArray(config?.panelClass) ? config.panelClass : [config?.panelClass]),
    ].filter(Boolean) as string[];

    const dialogConfig = {
      ...OCP_DEFAULT_DIALOG_CONFIG,
      ...config,
      panelClass: panelClasses,
    };
    return this._dialog.open(component, dialogConfig);
  }

  closeDialog<T, R = unknown>(ref: MatDialogRef<T, R>): void {
    ref.close();
  }
}
